import DashboardLayout from "../pages/Dashboard/Layout/DashboardLayout.vue";
const Attach = () => import(/* webpackChunkName: "sections" */"../pages/Sections/Attach.vue");

import i18n from '../i18n';

export default {
  path: '/sections',
  component: DashboardLayout,
  redirect: '/dashboard',
  name: 'Sections',
  children: [
    {
      path: ':id',
      name: 'Sections.Attach',
      component: Attach,
      meta: {
        name: i18n.t('survey.step1.title.create'),
        breadcrumbs: [
          {
            name: i18n.t('dashboard.dashboard'),
            link: 'Dashboard',
          },
          {
            name: 'Latarnia Rozwoju',
            disabled: true,
          },
          {
            nameGetter: 'participant/getParticipantName',
            disabled: true,
          },
          {
            name: i18n.t('section.breadcrumb'),
            link: 'Sections.Attach',
          },
        ],
      }
    },
  ]
};
