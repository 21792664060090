export default {
  first_name: 'Imię',
  last_name: 'Nazwisko',
  email: 'Adres email',
  status: 'Status',
  actions: 'Akcje',
  save: 'Powróć do badania',
  add: 'Dodaj',
  delete: {
    title: 'Czy na pewno chcesz usunąć tego respondenta?',
  },
  back: {
    title: 'Czy na pewno chcesz cofnać?',
    text: 'Wpisani respondenci nie zostaną zapisani',
    confirmButtonText: 'Cofnij',
  },
  create: 'Dodawanie respondentów',
  tooltips: {
    reminded: 'Wysłano przypomnienie',
    new: 'Nowy',
    invalid: 'Błędny adres e-mail',
    fulfilled: 'Wypełnił badanie',
    started: 'Rozpoczął badanie',
    correct: 'Otrzymał wiadomość',    
    waiting: 'Oczekuje na rozpoczęcie'
  }

}
