<template>
  <ul class="nav nav-mobile-menu">
    <li @click="performLogout">
      <a class="dropdown-toggle justify-center">
        <i class="material-icons">power_settings_new</i>
        <p class="">{{ $t('auth.logout') }}</p>
      </a>
    </li>
  </ul>
</template>
<script>
import { mapActions, mapState } from 'vuex';
export default {
  computed: {
    ...mapState('auth', [
      'user',
    ]),
  },
  methods: {
    ...mapActions('auth', [
        'logout',
    ]),

    async performLogout() {
      await this.logout();
      this.$router.push({ name: 'Login' });
    }
  }
}
</script>

<style lang="scss" scoped>
  .sidebar .nav i, .off-canvas-sidebar .nav i {
    float: 0;
    margin-right: 0 !important;
  }
  
  .sidebar .nav li a, .off-canvas-sidebar .nav li a {
    display: flex;
  }
</style>
