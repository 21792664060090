import api from '@/helpers/api';

export default {
  async getGoalQuestions({commit}, {examRoundId}) {
    const response = await api.get(`coach/exam-rounds/${examRoundId}/goal-questions`);

    const data = response.data.data;

    commit('setGoalQuestions', data);
  },

  async createGoalQuestion(store, {examRoundId, payload}) {
    await api.post(`coach/exam-rounds/${examRoundId}/goal-questions`, payload);
  }

}