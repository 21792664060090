<template>
    <md-card>
        <md-card-header class="md-card-header-icon md-card-header-blue">
            <div class="md-layout md-alignment-center-space-between">
                <div class="md-layout md-size-50">
                    <div class="card-icon">
                        <slot name="card-icon"></slot>
                    </div>
                    <h4 class="title">{{ title }}</h4>
                </div>
                <slot name="right-header-action"></slot>
            </div>
        </md-card-header>
        <md-card-content>
            <md-progress-bar
                md-mode="query"
                v-if="isLoading"
            ></md-progress-bar>
            <md-table class="paginated-table table-striped table-hover mt-3">
                <md-table-empty-state :md-description="$t('global.table.empty_description')"
                                      :md-label="$t('global.table.empty_label')"
                                      md-icon="notification_important">
                </md-table-empty-state>
                    <md-table-row class="header-row">
                        <md-table-cell
                            :key="column.title"
                            v-for="column in columns"
                        >
                            <div class="md-table-head-container">
                                <div class="md-table-head-label my-2">
                                    {{ column.title }}
                                </div>
                            </div>
                        </md-table-cell>
                        <md-table-cell>
                            <div class="md-table-head-container">
                                <div class="md-table-head-label my-2">
                                    {{ $t('global.table.actions') }}
                                </div>
                            </div>
                        </md-table-cell>
                    </md-table-row>

                    <md-table-row class="header-row">
                        <md-table-cell
                            :key="column.title"
                            v-for="column in columns"
                        >
                            <div class="md-table-head-container">
                                <div v-if="column.filterable">
                                    <md-field>
                                        <md-input
                                            v-model="filters[column.name]"
                                            @input="(value) => $emit('filter', `${column.name}:${value}`)"
                                            :size="column.title.length"
                                            :placeholder="$t('global.filter')"
                                        >
                                        </md-input>
                                    </md-field>
                                </div>
                            </div>
                        </md-table-cell>
                        <md-table-cell>
                            <md-button
                                class="md-simple"
                                @click="clearFilters"
                            >
                                <md-icon>filter_list</md-icon>
                                {{ $t('global.clear_filters') }}
                            </md-button>
                        </md-table-cell>
                    </md-table-row>
                    <md-table-row :key="index" v-for="(item, index) in tableData"  v-if="!isLoading">
                        <md-table-cell
                            style="width: 1px;"
                            v-if="$scopedSlots['rowPreActions']"
                        >
                            <slot name="rowPreActions" v-bind:item="item"></slot>
                        </md-table-cell>
                        <md-table-cell
                            :key="column.name"
                            :md-label="column.title"
                            :md-numeric="column.numeric"
                            md-sort-by="name"
                            v-for="column in columns"
                            v-html="column.calculatedValue ? column.calculatedValue(item) : item[column.name]"
                        >
                        </md-table-cell>
                        <md-table-cell
                            :md-label="$t('global.table.actions')"
                            v-if="$scopedSlots['rowActions']"
                        >
                            <slot name="rowActions" v-bind:item="item"></slot>
                        </md-table-cell>
                    </md-table-row>
            </md-table>
        </md-card-content>
        <md-card-actions
            md-alignment="space-between"
            v-if="(tableData.length && pagination) || $slots['footer-action']"
        >
            <template v-if="tableData.length && pagination">
                <p
                    class="card-category"
                    v-text="$t('global.pagination.showing', {
                      from                 : pagination.from,
                      to                   : pagination.to,
                      total                : pagination.total,
                    })"
                >
                </p>
                <pagination :perPage="pagination.per_page"
                            :total="pagination.total"
                            class="pagination-no-border pagination-success"
                            v-model="currentPage">
                </pagination>
            </template>
            <slot name="footer-action"></slot>
        </md-card-actions>
    </md-card>
</template>

<script>
  import Pagination from '@/components/Pagination';

  export default {
    name: "DataTable",

    components: {
      Pagination,
    },

    props: {
      title: {type: String, required: true},
      icon: {type: String, default: 'grid'},
      isLoading: {type: Boolean, default: false},
      tableData: {type: Array, required: true},
      pagination: {type: Object, required: false},
      columns: {type: Array, default: () => []},
    },

    data() {
      return {
        filters: {},
      };
    },

    computed: {
      currentPage: {
        get() {
          return this.pagination.current_page
        },
        set(value) {
          if (value !== this.pagination.current_page) {
            this.$emit('page-change', value)
          }
        }
      },
    },

    methods: {
      clearFilters() {
        this.filters = {};
        this.$nextTick(() => {
          this.$emit('clear-filters');
        });
      }
    }
  }
</script>

<style lang="scss" scoped>

    .animation-fb-loading {
        width: 55vw;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        .item {
            display: inline-block;
            border-radius: 3px;
            height: 20px;
            margin-top: 25px;
            background: #ccc;
            background: -webkit-gradient(linear, left top, right top, color-stop(8%, #ededed), color-stop(18%, #dddddd), color-stop(33%, #ededed));
            background: -webkit-linear-gradient(left, #ededed 8%, #dddddd 18%, #ededed 33%);
            background: linear-gradient(to right, #ededed 8%, #dddddd 18%, #ededed 33%);
            -webkit-background-size: 800px 104px;
            animation: fb-loading 5s linear;
        }

        .one {
            width: 82%;
        }

        .two {
            width: 13%;
        }
    }

    .empty-table-text {
        opacity: 0;
        animation: empty-table 1s 5s linear both;
    }

    @keyframes fb-loading {
        0% {
            background-position: -750px 0;
        }
        100% {
            background-position: 750px 0;
        }
    }

    @keyframes empty-table {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    .md-field {
        margin: 0;
    }

    .md-table-head-container {
        display: flex;
        flex-direction: column;
        height: auto;
    }

    .header-row td {
        vertical-align: top;
    }

</style>
