import api from '../../helpers/api';

export default {
    async createParticipantNoteAttachment(store, payload) {
        await api.post('/coach/participant-note-attachments', payload);
    },

    async updateParticipantNoteAttachment(store, { id, payload }) {
        await api.put(`/coach/participant-note-attachments/${id}`, payload);
    },

    async deleteParticipantNoteAttachment(store, id) {
        await api.delete(`/coach/participant-note-attachments/${id}`);
    },
}