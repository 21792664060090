import swal from "sweetalert2";
import i18n from "@/i18n";

async function deleteAlert(params) {
  const defaultParams = {
    icon: "question",
    customClass: {
      confirmButton: "md-button md-danger btn-fill",
      cancelButton: "md-button md-rose",
    },
    title: i18n.t("global.delete.title"),
    text: i18n.t("global.delete.text"),
    confirmButtonText: i18n.t("global.delete.confirm"),
    cancelButtonText: i18n.t("global.delete.cancel"),
    showCancelButton: true,
    reverseButtons: true,
    backdrop: `rgba(180, 180, 180, 0.4)`,
  };

  params = Object.assign(defaultParams, params);

  return await swal.fire(params);
}

async function successAlert(params) {
  const defaultParams = {
    icon: "success",
    customClass: {
      confirmButton: "md-button md-info btn-fill",
    },
    confirmButtonText: i18n.t("global.success.ok"),
    backdrop: `rgba(180, 180, 180, 0.4)`,
  };

  params = Object.assign(defaultParams, params);

  return await swal.fire(params);
}

async function errorAlert(params) {
  const defaultParams = {
    icon: "error",
    customClass: {
      confirmButton: "md-button md-danger btn-fill",
    },
    confirmButtonText: i18n.t("global.error.ok"),
    backdrop: `rgba(180, 180, 180, 0.4)`,
  };

  params = Object.assign(defaultParams, params);

  return await swal.fire(params);
}

async function confirmAlert(params) {
  const defaultParams = {
    icon: "question",
    customClass: {
      confirmButton: "md-button md-info btn-fill",
      cancelButton: "md-button md-rose",
      denyButton: "md-button md-rose",
    },
    confirmButtonText: i18n.t("global.confirm.ok"),
    cancelButtonText: i18n.t("global.confirm.cancel"),
    showCancelButton: true,
    reverseButtons: true,
    backdrop: `rgba(180, 180, 180, 0.4)`,
  };

  params = Object.assign(defaultParams, params);

  return await swal.fire(params);
}

async function infoAlert(params) {
  const defaultParams = {
    icon: "info",
    customClass: {
      confirmButton: "md-button md-info btn-fill",
    },
    confirmButtonText: i18n.t("global.error.ok"),
    backdrop: `rgba(180, 180, 180, 0.4)`,
  };

  params = Object.assign(defaultParams, params);

  return await swal.fire(params);
}

export { deleteAlert, successAlert, errorAlert, confirmAlert, infoAlert };
