import api from '../../helpers/api';

export default {
    async login({commit}, {email, password}) {
        const result = await api.post('auth/login', {
            email,
            password,
        });
        const data = result.data.data;
        commit('setUser', data.user);
        commit('setTokens', data.tokens);
        commit('setIsLogged', true);
    },

    async logout({commit}) {
        await api.post('auth/logout');
        commit('clearUser');
        commit('clearTokens');
        commit('setIsLogged', false);
    },

    async refresh({commit, state}) {
        commit('setIsLogged', false);
        const result = await api.post('auth/login/refresh', {
            refresh_token: state.tokens.refresh_token,
        });
        commit('setTokens', result.data);
    },

    async getPasswordReset({commit}, token) {
        const result = await api.get(`password/find/${token}`);

        commit('setPasswordReset', result.data.data);
    }
}
