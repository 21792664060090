import api from '../../helpers/api';

export default {
    async getParticipantGoals({ commit }, payload) {
        const result = await api.get('coach/participant-goals', { params: payload });

        commit('setParticipantGoals', result.data.data.data);
    },

    async createParticipantGoal(store, payload) {
        await api.post('coach/participant-goals', payload);
    },

    async updateParticipantGoal(store, { id, payload }) {
        await api.put(`coach/participant-goals/${id}`, payload);
    },

    async deleteParticipantGoal(store, id) {
        await api.delete(`coach/participant-goals/${id}`);
    }
}
