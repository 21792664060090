export default {
    login_title: 'Logowanie',
    login: 'Zaloguj się',
    email: 'E-mail',
    password: 'Hasło',
    letsgo: 'Dalej!',
    send: "Wyślij",
    wrong_credentials: 'Nieprawidłowe dane!',
    logout: 'Wyloguj',
    forget: 'Przypomnij hasło',
    forgot: 'Nie pamiętasz hasła?',
    password_confirmation: 'Powtórz hasło',
    reset: 'Resetowanie hasła',
}
