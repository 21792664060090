import Vue from 'vue'
import VueI18n from 'vue-i18n'

import messages from './trans';

Vue.use(VueI18n)

export default new VueI18n({
  locale: 'pl',
  fallbackLocale: 'pl',
  messages,
})