export default {
  see: 'Podgląd',
  name: 'Badanie',
  show: 'Pokaż',
  edit: 'Edytuj',
  status: 'Status',
  end_date: 'Data zakończenia',
  progress: 'Postęp',
  in_progress: 'W trakcie',
  finished: 'Zakończone',
  results: 'Wyniki',
  report: 'Wygeneruj raport',
  start: 'Rozpocznij badanie',
  statuses: {
    report_ready: 'Raport gotowy',
    report_sent: 'Wysłano raport',
    draft: 'Szkic',
    first_round_report: 'Wysłano raport właściwy',
    first_round_progress: 'W trakcie badania',
    first_round_finished: 'Zakończono badanie',
    second_round_report: 'Wysłano raport ewaluacji',
    second_round_progress: 'W trakcie ewaluacji',
    second_round_finished: 'Zakończono ewaluację',
  },
  delete: 'Usuń badanie',
  edit_participant: 'Edytuj dane uczestnika',
  delete_confirmation: 'Czy na pewno chcesz usunąć badanie dla {firstName} {lastName} wraz z wszystkimi jego danymi?',
  this_is_draft: 'To jest szkic badania.',
  click_here: 'Dokończ tworzenie badania',

  exam_created: 'Utworzono egzamin',
  what_you_want_to_do: 'Co chcesz dalej zrobić?',
  continue: 'Kontunuuj tworzenie badania',
  save_draft: 'Zapisz jako szkic',
  no_second_round_title: 'Wystąpił błąd!',
  no_second_round: 'Nie można rozpocząć ewaluacji!',
  create_round: 'Konfiguracja dat',
  question_triggered_by: 'Pytanie wywoływane przez odpowiedź',
  unsettled: 'Nieustalone',
}
