export default {
  title: "Wszystkie materiały",
  file_name: "Nazwa pliku",
  add: "Dodaj plik",
  send_email_title: "Wyślij link do pobrania do uczestnika",
  send_email_alert: "Jesteś pewien?",
  email_sent: "Przesłano link do pobrania do uczestnika",
  empty: "Brak materiałów",
  send: "Wyślij do uczestnika",
  download: "Pobierz materiał",
};
