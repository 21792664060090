import DashboardLayout from "../pages/Dashboard/Layout/DashboardLayout.vue";
const Create = () => import(/* webpackChunkName: "exam" */ "../pages/Exam/Create.vue");
const Edit = () => import(/* webpackChunkName: "exam" */ "../pages/Exam/Edit.vue");
const EditParticipant = () => import(/* webpackChunkName: "exam" */ "../pages/Exam/EditParticipant.vue");
const Show = () => import(/* webpackChunkName: "exam" */ "../pages/Exam/Show.vue");
const Results = () => import(/* webpackChunkName: "exam" */ "../pages/Exam/Results");
const Comment = () => import(/* webpackChunkName: "exam" */ "../pages/Exam/Comment");

import i18n from '../i18n';
const CreateRound = () => import(/* webpackChunkName: "exam" */"../pages/Exam/CreateRound");

export default {
    path: '/survey',
    component: DashboardLayout,
    redirect: '/survey/create',
    name: 'Exam',
    children: [
        {
            path: 'exam',
            name: 'Exam.Create',
            component: Create,
            meta: {
                name: i18n.t('survey.step1.title.create'),
                breadcrumbs: [
                    {
                        name: i18n.t('dashboard.dashboard'),
                        link: 'Dashboard',
                    },
                    {
                        nameGetter: 'studies/getStudyName',
                        link: 'Exam.Create',
                        disabled: true,
                    },
                ],
            },
        },
        {
            path: 'exam/:id',
            name: 'Exam.Edit',
            component: Edit,
            meta: {
                name: i18n.t('survey.step1.title.edit'),
                breadcrumbs: [
                    {
                        name: i18n.t('dashboard.dashboard'),
                        link: 'Dashboard',
                    },
                    {
                        name: 'Latarnia Rozwoju',
                        disabled: true,
                    },
                    {
                        nameGetter: 'participant/getParticipantName',
                        disabled: true,
                    },
                    {
                        name: i18n.t('exam.edit'),
                        link: 'Exams.Edit',
                    },
                ],
            }
        },
        {
            path: 'exam/:id/edit-participant',
            name: 'Exam.EditParticipant',
            component: EditParticipant,
            meta: {
                name: i18n.t('survey.step1.title.edit'),
                breadcrumbs: [
                    {
                        name: i18n.t('dashboard.dashboard'),
                        link: 'Dashboard',
                    },
                    {
                        name: 'Latarnia Rozwoju',
                        disabled: true,
                    },
                    {
                        nameGetter: 'participant/getParticipantName',
                        disabled: true,
                    },
                    {
                        name: i18n.t('exam.edit_participant'),
                        link: 'Exam.EditParticipant',
                    },
                ],
            }
        },
        {
            path: 'exam/:id/show',
            name: 'Exam.Show',
            component: Show,
            meta: {
                name: i18n.t('exam.show'),
                breadcrumbs: [
                    {
                        name: i18n.t('dashboard.dashboard'),
                        link: 'Dashboard',
                    },
                    {
                        name: 'Latarnia rozwoju',
                        link: 'Exam.Show',
                        disabled: true,
                    },
                    {
                        nameGetter: 'participant/getParticipantName',
                        link: 'Exam.Show',
                    },
                ],
            },
        },
        {
            path: 'exam/:id/comment/:roundId',
            name: 'Exam.Comment',
            component: Comment,
            meta: {
                name: i18n.t('examRoundComment.comment'),
                breadcrumbs: [
                    {
                        name: i18n.t('dashboard.dashboard'),
                        link: 'Dashboard',
                    },
                    {
                        name: 'Latarnia rozwoju',
                        link: 'Exam.Show',
                        disabled: true,
                    },
                    {
                        nameGetter: 'participant/getParticipantName',
                        link: 'Exam.Show',
                    },
                    {
                        name: i18n.t('examRoundComment.comment'),
                        link: 'Exam.Comment',
                    }
                ],
            },
        },
        {
            path: 'exam/:id/results/:roundId',
            name: 'Exam.Results',
            component: Results,
            meta: {
                name: i18n.t('exam.results'),
                breadcrumbs: [
                    {
                        name: i18n.t('dashboard.dashboard'),
                        link: 'Dashboard',
                    },
                    {
                        name: 'Latarnia rozwoju',
                        link: 'Exam.Show',
                        disabled: true,
                    },
                    {
                        nameGetter: 'participant/getParticipantName',
                        link: 'Exam.Show',
                    },
                    {
                        name: i18n.t('exam.results'),
                        link: 'Exam.Results',
                    }
                ],
            },
        },
        {
            path: 'exam/:id/round',
            name: 'Exam.CreateRound',
            component: CreateRound,
            meta: {
                name: i18n.t('exam.results'),
                breadcrumbs: [
                    {
                        name: i18n.t('dashboard.dashboard'),
                        link: 'Dashboard',
                    },
                    {
                        name: 'Latarnia rozwoju',
                        link: 'Exam.Show',
                        disabled: true,
                    },
                    {
                        nameGetter: 'participant/getParticipantName',
                        link: 'Exam.Show',
                    },
                    {
                        name: i18n.t('exam.create_round'),
                        link: 'Exam.CreateRound',
                    }
                ],
            },
        }
    ],
};
