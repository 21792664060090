import api from '../../helpers/api';

export default {
    async storeExam({ commit }, payload) {
        const response = await api.post(`coach/exams/2`, payload); // Latarnia 2.0
        commit('setExam', response.data.data);
    },

    async updateExam(store, { id, payload }) {
       await api.put(`coach/exams/${id}`, payload);
    },

    async getExam({ commit }, { id, payload}) {
        const response = await api.get(`coach/exams/${id}`, payload);
        commit('setExam', response.data.data);
    },

    async getExams({commit}, payload) {
        const response = await api.get('/coach/exams', {params: payload});
        const data = response.data.data;

        commit('setExams', data.data);
        commit('setPagination', data);
    },

    async getResult({ commit }, examId) {
        const response = await api.get(`coach/exams/${examId}/result`);

        commit('setResult', response.data);
    },

    async deleteExam(store, examId) {
        await api.delete(`coach/exams/${examId}`);
    },
}
