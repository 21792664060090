<template>
  <div class="px-12">
    <ul v-if="$route.meta.hasOwnProperty('breadcrumbs')" class="breadcrumbs">
      <li v-for="(breadcrumb, index) in $route.meta.breadcrumbs" :key="index">
        <router-link
          v-if="!breadcrumb.disabled"
          :to="{ name: breadcrumb.link, params: $route.params }"
          :exact="true"
        >
          {{ name(breadcrumb) }}
        </router-link>
        <span v-else>
          {{ name(breadcrumb) }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "BreadCrumbs",

  methods: {
    name(breadcrumb) {
      return breadcrumb.hasOwnProperty("nameGetter")
        ? this.$store.getters[breadcrumb.nameGetter]
        : breadcrumb.name;
    },
  },
};
</script>

<style scoped lang="scss">
.breadcrumbs {
  display: flex;
  list-style-type: none;

  li {
    font-size: 14px;
    &:not(:last-child) {
      ::after {
        content: ">";
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }
    }
  }

  @media (max-width: 992px) {
    li {
      &:not(:last-child) {
        ::after {
          content: ">";
          font-size: 12px;
          margin-left: 0.2rem;
          margin-right: 0.2rem;
        }
      }
    }
  }
}
.router-link-active {
  font-weight: 800;
}
</style>
