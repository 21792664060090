import DashboardLayout from '@/pages/Dashboard/Layout/DashboardLayout.vue'

// Dashboard pages
import Dashboard from '@/pages/Dashboard/Dashboard.vue';

// Pages
import authPages from './auth';
import examPages from './exam';
import respondentsPages from './respondents';
import sectionsPages from './sections';

// i18n
import i18n from '../i18n';


const routes = [{
    path: '/',
    redirect: '/dashboard',
    name: 'Home'
}, {
    path: '*',
    redirect: '/dashboard'
},
    authPages,
    examPages,
    respondentsPages,
    sectionsPages,
{
    path: '/',
    component: DashboardLayout,
    children: [{
        path: 'dashboard',
        name: 'Dashboard',
        components: {
            default: Dashboard
        },
        meta: {
            name: i18n.t('dashboard.dashboard'),
            breadcrumbs: [{
                name: i18n.t('dashboard.dashboard'),
                link: 'Dashboard',
            },],
        }
    },]
},

];

export default routes