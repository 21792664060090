import axios from 'axios';

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL, // eslint-disable-line
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Accept': 'application/json'
  }
});


export default api;
