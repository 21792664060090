export default {
  table: {
    actions: "Akcje",
    empty_label: "Ale pusto...",
    empty_description: "Nic tutaj jeszcze nie ma :'(",
  },
  pagination: {
    showing: "Wyświetlanie od {from} do {to}, {total} wszystkich elementów.",
  },
  success: {
    ok: "OK",
  },
  delete: {
    title: "Czy na pewno usunąć?",
    text: "Operacja jest nieodwracalna",
    confirm: "Usuń",
    cancel: "Anuluj",
  },
  confirm: {
    ok: "Kontynuuj",
    cancel: "Anuluj",
  },
  error: {
    ok: "OK",
  },
  add: "Dodaj",
  edit: "Edytuj",
  remove: "Usuń",
  save: "Zatwierdź",
  cancel: "Anuluj",
  back: "Cofnij",
  clear_filters: "Wyczyść filtry",
  filter: "Filtruj...",
};
