<template>
    <div>
        <div :class="[ 'md-collapse', activeCollapse(index + 1), {[getColorCollapse(colorCollapse)]: true}]"
             v-for="(item, index) in Object.values(collapse)"
             :key="item">
            <div class="md-collapse-label">
                <h5 class="md-collapse-title flex items-center"
                    :class="[{'text-active': isChosen(index)}]">
                    <slot :name="getCollapseSection(index + 1)"></slot>
                    <span class="ml-4 w-full"
                          @click="toggle(index + 1)">
                        <span class="text-xl uppercase">{{item}}</span>
                        <md-icon class="ml-auto mr-2">{{icon}}</md-icon>
                    </span>
                </h5>
            </div>

            <collapse-transition>
                <div v-show="getActiveCollapse(index + 1)"
                     class="md-collapse-content">
                    <slot :name="getCollapseContent(index + 1)"></slot>
                </div>
            </collapse-transition>
        </div>
    </div>
</template>

<script>

    import {CollapseTransition} from 'vue2-transitions'

    export default {
        name: 'collapse',
        components: {
            CollapseTransition,
        },
        props: {
            collapse: Object,
            icon: String,
            colorCollapse: String,
        },

        data(){
            return {
                isActive: 0,
                active: [],
            }
        },
        methods: {
            getActiveCollapse(index){
                return this.isActive === index
            },
            activeCollapse (index){
                return {
                    'is-active': this.isActive === index
                }
            },
            toggle (index) {
                if (index === this.isActive) {
                    this.isActive = 0;
                } else {
                    this.isActive = index;
                }
            },
            getCollapseContent: function (index) {
                return 'md-collapse-pane-' + index + ''
            },
            getCollapseSection: function (index) {
                return 'section-' + index + ''
            },
            getColorCollapse: function (colorCollapse) {
                return 'md-' + colorCollapse + ''
            },
            isChosen: function (index) {
                return this.active.filter((item) => {
                    return item === index;
                }).length
            },
        },

        mounted: function () {
            this.$on('chosen-section', (chosen) => {
                let index = this.active.indexOf(chosen);

                if (index === -1) {
                    this.active.push(chosen);
                } else {
                    this.active.splice(index, 1);
                }
            })
        }
    }
</script>

<style lang="scss" scoped>
    .text-center {
        display: flex;
    }

    .md-collapse-title {
        display: flex;
    }

    .md-collapse.md-info.is-active .md-collapse-label .md-collapse-title, .md-collapse.md-info.is-active .md-collapse-label .md-collapse-title .md-icon {
        color: black !important;
    }

    .md-collapse.md-info .md-collapse-label .md-collapse-title.text-active {
        color: blue !important;
    }
</style>
