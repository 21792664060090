import AuthLayout from '@/pages/Dashboard/Layout/AuthLayout.vue'

const Login = () => import(/* webpackChunkName: "auth" */'@/pages/Auth/Login.vue');
const ForgetPassword = () => import(/* webpackChunkName: "auth" */'@/pages/Auth/ForgetPassword.vue');
const ResetPassword = () => import(/* webpackChunkName: "auth" */'@/pages/Auth/ResetPassword.vue');
import i18n from '../i18n';

export default {
  path: '/auth',
  component: AuthLayout,
  name: 'Authentication',
  children: [
    {
      path: 'login',
      name: 'Auth.Login',
      component: Login,
      meta: {
        name: i18n.t('auth.login'),
      }
    },
    {
      path: 'forget',
      name: 'Auth.ForgetPassword',
      component: ForgetPassword,
      meta: {
        name: i18n.t('auth.forget'),
      },
    },
    {
      path: 'reset/:token',
      name: 'Auth.ResetPassword',
      component: ResetPassword,
      meta: {
        name: i18n.t('auth.reset'),
      },
    }
  ]
};
