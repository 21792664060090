export default {
  setUser(state, data) {
    state.user = data;
  },
  clearUser(state) {
    state.user = {};
  },
  setTokens(state, data) {
    state.tokens = data;
  },
  clearTokens(state) {
    state.tokens = {};
  },
  setIsLogged(state, isLogged) {
    state.isLogged = isLogged;
  },
  setPasswordReset(state, data) {
    state.passwordReset = data;
  },
}
