import auth from "./auth";
import dashboard from "./dashboard";
import global from "./global";
import survey from "./survey";
import section from "./section";
import goals from "./goals";
import notes from "./notes";
import importTranslations from "./import";
import exam_round from "./examRound";
import respondents from "./respondents";
import results from "./results";
import exam from "./exam";
import examMedia from "./examMedia";
import participant from "./participant";
import studyType from "./studyType";
import support from "./support";
import report from "./report";
import examRoundComment from "./examRoundComment";

export default {
  auth,
  global,
  dashboard,
  survey,
  section,
  goals,
  notes,
  import: importTranslations,
  exam_round,
  participant,
  respondents,
  results,
  exam,
  examMedia,
  studyType,
  support,
  report,
  examRoundComment,
};
